<template>
  <KTCard
    :page="page"
    :isForm="true"
    :isEdit="isEdit"
    @submit="onSubmit"
    :isLoading="ApiIsLoading"
    :cancelTo="parentRoute"
    :readonly="finalizado"
  >
    <template #body>
      <b-form-group
        label-size="lg"
        label-cols-md="4"
        label-cols-lg="3"
        class="mb-0"
      >
        <template #label> Tipo <span class="text-danger">*</span> </template>
        <template #default>
          <KTSelect
            v-model="form.idtipo_despesa_operacional"
            :options="form_options.idtipo_despesa_operacional"
            :required="!finalizado"
            :disabled="finalizado"
          />
        </template>
      </b-form-group>

      <b-row>
        <b-col
          md="6"
          lg="4"
          xl="3"
          v-for="(custo, index) in form.despesas"
          :key="'ano-' + custo.ano"
        >
          <b-card no-body class="p-3 mt-8">
            <p class="font-weight-bold">Ano {{ custo.ano }}</p>
            <b-form-group class="mb-0" label-size="lg">
              <template #label>
                Valor <span class="text-danger">*</span>
              </template>
              <template #default>
                <b-input-group :prepend="simbolo_moeda">
                  <KTInputNumeric
                    v-model="form.despesas[index].valor"
                    :precision="2"
                    autocomplete="off"
                    :required="!finalizado"
                    :disabled="finalizado"
                  />
                </b-input-group>
              </template>
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </KTCard>
</template>

<script>
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";
import CommonDataService from "@/core/services/commondata.service";

export default {
  name: "PipDespesaOperacionalForm",
  props: {
    idplano_investimento: {
      required: true
    },
    idpip_despesa_operacional: {
      default: null
    }
  },
  mixins: [ApiMixin, HelpersMixin],
  data() {
    return {
      page: {
        icon: "la la-thumbs-down",
        title: "Despesas Operacionais",
        description: `Selecione o Tipo da despesa e em seguida o valor médio
          previsto para cada ano. Seja criterioso ao definir o valor para
          evitar inconsistência no resultado da análise.`
      },
      form: {
        despesas: []
      },
      form_options: {
        idtipo_despesa_operacional: []
      },
      anos: 0,
      simbolo_moeda: null,
      tipo_plano_investimento: null,
      finalizado: true
    };
  },
  computed: {
    apiResource() {
      return `pip/${this.idplano_investimento}/despesa_operacional`;
    },
    manual() {
      return {
        session: "despesa_operacional",
        tipo: this.tipo_plano_investimento
      };
    },
    isEdit() {
      return !!this.idpip_despesa_operacional;
    },
    parentRoute() {
      return {
        name: "pip_despesa_operacional_list",
        params: { idplano_investimento: this.idplano_investimento }
      };
    }
  },
  methods: {
    getPageData() {
      const requests = [
        this.Api.GetData(this.idpip_despesa_operacional),
        CommonDataService.getTipoDespesaOperacional()
      ];

      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETDATA = 0;
        const R_TIPODESPESAOPERACIONAL = 1;

        this.simbolo_moeda = res[R_GETDATA].simbolo_moeda;
        this.anos = res[R_GETDATA].anos;
        this.form.idtipo_despesa_operacional =
          res[R_GETDATA].idtipo_despesa_operacional ?? null;
        this.form_options.idtipo_despesa_operacional =
          res[R_TIPODESPESAOPERACIONAL];

        if (res[R_GETDATA].despesas && res[R_GETDATA].despesas.length) {
          this.form.despesas = res[R_GETDATA].despesas;
        } else {
          this.form = {
            despesas: []
          };
          for (let i = 1; i <= this.anos; i++) {
            this.form.despesas.push({
              idpip_despesa_operacional: this.idpip_despesa_operacional,
              ano: i
            });
          }
        }
        this.tipo_plano_investimento = res[R_GETDATA].tipo_plano_investimento;
        this.finalizado = res[R_GETDATA].finalizado;

        this.initManual(this.manual.session, this.manual.tipo);
      };

      this.ApiGetPageData(requests, resolveFuntion);
    },
    onSubmit() {
      this.ApiSubmit(this.idpip_despesa_operacional, this.form);
    }
  },
  mounted() {
    this.getPageData();
  }
};
</script>
